import React from 'react'
import styled from 'styled-components'
import { COLOR_GREY_90 } from './tokens'

const List = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
`

const Item = styled.li`
    border-top: 1px solid ${COLOR_GREY_90};
`

type Props = {
    // Sorry. Fixme: https://issues.internations.org/browse/INOTF-1775
    children: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

const ListOfItems = ({ children }: Props) => (
    <List>
        {React.Children.map(children, item => (
            <Item key={item.key}>{item}</Item>
        ))}
    </List>
)

export default ListOfItems
