import React from 'react'
import styled from 'styled-components'

type AspectRatioProps = {
    children: React.ReactNode
    ratio: '16x9'
}

const AspectRatioContainer = styled.div<AspectRatioProps>`
    position: relative;

    &:before {
        display: block;
        content: '';
        width: 100%;
        padding-top: ${props => (props.ratio === '16x9' ? '56.25%' : '100%')};
    }
`

const AspectRatioContent = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
`

const AspectRatio = ({ children, ratio }: AspectRatioProps) => (
    <AspectRatioContainer ratio={ratio}>
        <AspectRatioContent>{children}</AspectRatioContent>
    </AspectRatioContainer>
)

export default AspectRatio
