import React from 'react'
import styled from 'styled-components'
import Icon, { ImageIconName } from './Icon'
import { COLOR_GREY_40 } from './tokens'

type Props = {
    children: React.ReactNode
    icon: ImageIconName
    isBig?: boolean
}

const iconStyles = {
    marginRight: '4px',
    opacity: 0.4,
}

type ContainerProps = {
    big?: boolean
}

const Container = styled.div<ContainerProps>`
    display: flex;
    align-items: center;
    font-size: 12px;
    font-size: '14px';
    line-height: 18px;
    color: ${COLOR_GREY_40};
`

const MetaInformation = ({ children, icon }: Props) => (
    <Container>
        <Icon icon={icon} style={iconStyles} />
        {children}
    </Container>
)

export default MetaInformation
