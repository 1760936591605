import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ImageIconName } from './Icon'
import MetaInformation from './MetaInformation'
import Button from './Button'
import AspectRatio from './AspectRatio'
import { TeaserTitle } from './typography'
import { BREAKPOINTS, PADDING_STANDARD, BOX_SHADOW_Z3 } from './tokens'
import appendQueryParameters from '../utils/getQueryParams'

type TeaserProps = {
    title: string
    url: string
    image: string
    actionText?: string
    metaInfo?: { icon: ImageIconName; text: React.ReactNode }[]
    onAction?: () => void
    className?: string
    currentUrl: string
    params: string
}

const Container = styled.div`
    border-radius: 4px;
    box-shadow: ${BOX_SHADOW_Z3};
    overflow: hidden;
`

const Scrim = styled.div`
    position: absolute;
    width: 100%;
    bottom: 0;
    padding: 16px;
    background: linear-gradient(-180deg, transparent 0%, rgb(0, 0, 0) 100%);
`

const TeaserFooter = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 8px;

    @media (min-width: ${BREAKPOINTS.tablet}px) {
        padding: ${PADDING_STANDARD}px;
    }
`

const HalfSpaceTop = styled.div`
    margin-top: ${PADDING_STANDARD / 2}px;
`

const BgImage = styled.div`
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
`

const Teaser = ({ className, title, url, metaInfo, actionText, onAction, image, currentUrl, params }: TeaserProps) => {
    const [bgImage, updateBgImage] = useState('')

    const metaInfoMarkup =
        metaInfo !== undefined ? (
            <div>
                {metaInfo.map(({ icon, text }, index) => {
                    const item = (
                        <MetaInformation key={icon} icon={icon}>
                            {text}
                        </MetaInformation>
                    )
                    return index ? <HalfSpaceTop key={icon}>{item}</HalfSpaceTop> : item
                })}
            </div>
        ) : null

    // Delay image download after js is executed.
    // TODO: Use IntersectionObserver/lib to make it actually lazy
    useEffect(() => {
        updateBgImage(image)
    }, [image])

    return (
        <Container className={className}>
            <a
                href={appendQueryParameters(url, currentUrl, params)}
                title={title}
                style={{ position: 'relative', display: 'block' }}
            >
                <AspectRatio ratio="16x9">
                    <BgImage className={`${className}-cover-image`} style={{ backgroundImage: `url('${bgImage}')` }} />
                </AspectRatio>
                <Scrim>
                    <TeaserTitle white>{title}</TeaserTitle>
                </Scrim>
            </a>
            {metaInfoMarkup || actionText !== undefined ? (
                <TeaserFooter>
                    {metaInfoMarkup}
                    <Button className={`${className}-btn`} onClick={onAction}>
                        {actionText}
                    </Button>
                </TeaserFooter>
            ) : null}
        </Container>
    )
}

export default Teaser
