/* eslint react/no-danger: 0 */
import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import WhiteBox from '../components/WhiteBox'
import { Heading } from '../components/typography'
import { COLOR_WHITE, COLOR_BLUE, COLOR_HOVER_BLUE, PADDING_STANDARD, BOX_SHADOW_Z3, BREAKPOINTS } from './tokens'
import Button from './Button'

const Spacing = styled.div`
    margin-top: ${PADDING_STANDARD}px;
`

const MainUSPContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: around;
    > h2 {
        text-align: center;
        line-height: 30px;
    }
    > a {
        margin: 0 auto;
        width: 100%;

        @media (min-width: ${BREAKPOINTS.tablet}px) {
            width: auto;
        }
    }
`

const UPSContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 12px 0px;

    @media (min-width: ${BREAKPOINTS.tablet}px) {
        flex-direction: row;
    }
`

const USPPictogramContainer = styled.div`
    display: flex;
    text-align: left;
    width: 100%;
    justify-content: start;
    > img {
        width: 80px;
        height: 80px;
        aspect-ratio: 1:1;
    }
    p {
        color: rgba(0, 0, 0, 0.87);
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        max-width: calc(100% - 80px);
        margin: auto;
    }

    @media (min-width: ${BREAKPOINTS.tablet}px) {
        width: 30%;
        display: inline-block;
        vertical-align: top;
        p {
            display: block;
            max-width: 174px;
            text-align: center;
            font-weight: 600;
            margin: 10px auto;
        }
    }
`

const PictureContainer = styled.div`
    display: flex;
    justify-content: center;
    margin: 12px 8px 12px 0px;

    @media (min-width: ${BREAKPOINTS.tablet}px) {
        width: 100%;
        margin: 0 auto;
    }
`

const StyledButton = styled(Button)`
    margin: 0px auto;
    line-height: 40px;
    background: ${COLOR_BLUE};
    color: ${COLOR_WHITE};
    padding: 0 16px;
    width: 100%;

    &:hover {
        text-decoration: none;
        background-color: ${COLOR_HOVER_BLUE};
        box-shadow: ${BOX_SHADOW_Z3};
        cursor: pointer;
    }

    @media (min-width: ${BREAKPOINTS.tablet}px) {
        width: auto;
    }
`

const USP = () => {
    const data = useStaticQuery(graphql`
        query {
            meetPeopleImage: file(relativePath: { eq: "pictogram/meet_people.png" }) {
                childImageSharp {
                    fixed(height: 80, width: 80) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            socializeImage: file(relativePath: { eq: "pictogram/socialize.png" }) {
                childImageSharp {
                    fixed(height: 80, width: 80) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            getInfoImage: file(relativePath: { eq: "pictogram/get_info.png" }) {
                childImageSharp {
                    fixed(height: 80, width: 80) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    const renderedBlock = (
        <>
            <Spacing />
            <WhiteBox>
                <MainUSPContainer>
                    <Heading>An amazing community is waiting for you!</Heading>
                    <UPSContainer>
                        <USPPictogramContainer>
                            <PictureContainer>
                                <Img style={{ maxHeight: '80px' }} fixed={data.meetPeopleImage.childImageSharp.fixed} />
                            </PictureContainer>
                            <p>Meet international people at local events</p>
                        </USPPictogramContainer>
                        <USPPictogramContainer>
                            <PictureContainer>
                                <Img style={{ maxHeight: '80px' }} fixed={data.socializeImage.childImageSharp.fixed} />
                            </PictureContainer>
                            <p>Socialize, enjoy hobbies, and make friends</p>
                        </USPPictogramContainer>
                        <USPPictogramContainer>
                            <PictureContainer>
                                <Img style={{ maxHeight: '80px' }} fixed={data.getInfoImage.childImageSharp.fixed} />
                            </PictureContainer>
                            <p>Get info, and feel at home abroad!</p>
                        </USPPictogramContainer>
                    </UPSContainer>
                    <a href="/registration?trigger=usp" title="InterNations | Join Now">
                        <StyledButton>JOIN THE COMMUNITY NOW</StyledButton>
                    </a>
                </MainUSPContainer>
            </WhiteBox>
        </>
    )

    return <section id="ups-cta">{renderedBlock}</section>
}

export default USP
