import React, { MouseEventHandler } from 'react'
import styled from 'styled-components'
import { FixedObject } from 'gatsby-image'
import get from 'lodash/get'

import UserImage, { SIZES } from './UserImage'
import Flag from './Flag'
import { Testimonial as WpTestimonial, User } from '../types/wordpress'
import { useModalDispatch, actions } from './modal/ModalContext'

const TestimonialsContainer = styled.ul`
    margin: 0;
    padding: 0;
    li:last-child {
        margin-bottom: 0;
    }
`
const TestimonialItem = styled.li`
    display: flex;
    margin-bottom: 16px;
`

const TextContainer = styled.div`
    padding-left: 14px;
    p {
        margin: 0;
    }
`

const UserContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const FlagContainer = styled.div`
    display: flex;
    justify-content: center;
`

const Divider = styled.div`
    margin: 2px;
`

const getProfilePictureProps: (user: User) => FixedObject | null = user => {
    const src = get(user, 'profilePicture.localFile.publicURL')

    if (!src) {
        return null
    }

    const size = parseInt(SIZES.normal, 10)

    const fixedObject: FixedObject = {
        src,
        width: size,
        height: size,
        srcSet: '',
    }

    return fixedObject
}

type EventHandlers = {
    onClick: MouseEventHandler
}

type TestimonialProps = {
    testClass: string
    testimonial: WpTestimonial
    eventHandlers: EventHandlers
}

const Testimonial = ({ testClass, testimonial: { user, text }, eventHandlers: { onClick } }: TestimonialProps) => (
    <TestimonialItem className={testClass} key={user.name}>
        <UserContainer onClick={onClick}>
            <UserImage
                className={`${testClass}-user-image`}
                gender={user.gender}
                image={getProfilePictureProps(user)}
            />
            <FlagContainer>
                <Flag size="small" iocCode={user.origin.countryCodeIoc} />
                <Divider />
                <Flag size="small" iocCode={user.residence.countryCodeIoc} />
            </FlagContainer>
        </UserContainer>
        <TextContainer>
            <strong>{user.name}</strong>
            <p>{text}</p>
        </TextContainer>
    </TestimonialItem>
)

const Testimonials = ({
    testimonials,
    isBelowDesktop = false,
}: {
    testimonials: [WpTestimonial, ...WpTestimonial[]]
    isBelowDesktop?: boolean
}) => {
    const dispatch = useModalDispatch()
    const onClick = () =>
        dispatch(
            actions.openModal({
                title: 'Network & connect',
                subTitle: 'Become a member to browse and search profiles.',
                trigger: 'masked_user',
            }),
        )
    const eventHandlers = { onClick }

    return (
        <TestimonialsContainer>
            {isBelowDesktop ? (
                <Testimonial
                    testClass="t-testimonial-mobile"
                    testimonial={testimonials[0]}
                    eventHandlers={eventHandlers}
                />
            ) : (
                testimonials.map(testimonial => (
                    <Testimonial
                        testClass="t-testimonial-desktop"
                        key={testimonial.text}
                        testimonial={testimonial}
                        eventHandlers={eventHandlers}
                    />
                ))
            )}
        </TestimonialsContainer>
    )
}

export default Testimonials
