import React from 'react'
import { ImageIconName } from './Icon'
import Date from './Date'
import SingleTeaser from './SingleTeaser'

export type Event = {
    id: string
    url: string
    eventId: number
    startDate: string
    endDate: string
    name: string
    description: string
    attendees: number
    image: string
    location: {
        name: string | null
        address: {
            streetAddress: string | null
            addressLocality: string | null
        }
    }
}

type EventTeaserProps = {
    event: Event
    onAttend: () => void
    currentUrl: string
    params: string
}

const EventTeaser = ({ event, onAttend, currentUrl, params }: EventTeaserProps) => {
    const { startDate, name, attendees, url, image, description } = event

    const metaInfo = [{ icon: 'calendar' as ImageIconName, text: <Date date={startDate} /> }]
    if (attendees) {
        metaInfo.push({
            icon: 'group' as ImageIconName,
            text: (
                <>
                    {attendees} {attendees > 1 ? 'attendees' : 'attendee'}
                </>
            ),
        })
    }

    return (
        <SingleTeaser
            className="t-event-teaser"
            title={name}
            url={url}
            metaInfo={metaInfo}
            actionText="Attend"
            onAction={onAttend}
            image={image}
            currentUrl={currentUrl}
            params={params}
            description={description}
        />
    )
}

export default EventTeaser
