import React from 'react'
import { Link as WpLink } from '../types/wordpress'
import { SidebarHeading } from './typography'
import WhiteBox from './WhiteBox'
import LinkBox from './LinkBox'

export type GuideType = {
    guides: WpLink[]
    location_type: string
    name: string
    slug: string
}

const GuideLinkBox = ({
    headline,
    guides,
    testClass,
    currentUrl,
    params,
}: {
    headline: string | null
    guides: WpLink[] | null
    testClass: string
    currentUrl: string
    params: string
}) => {
    if (!guides) {
        return null
    }

    const links = guides.filter(link => link.title !== null)

    if (links.length === 0) {
        return null
    }

    return (
        <>
            <SidebarHeading>{headline}</SidebarHeading>
            <WhiteBox>
                <LinkBox
                    links={links.map(({ url, title }) => ({
                        url,
                        title,
                        testClass: `${testClass}-${title.replace(/[\s\r\n\\]+/g, '-').toLowerCase()}`,
                    }))}
                    currentUrl={currentUrl}
                    params={params}
                />
            </WhiteBox>
        </>
    )
}

export default GuideLinkBox
