import React from 'react'
import styled from 'styled-components'
import { COLOR_GREY_90 } from './tokens'
import Copy from '../components/Copy'

const TableContainer = styled.div`
    width: 100%;
`
const TableHtml = styled.table`
    width: 100%;
`

const TableHeader = styled.thead`
    font-size: 1.1em;
    font-weight: bold;
`

const StyledParagraph = styled.p`
    margin-top: 10px;
    border-top: 1px solid ${COLOR_GREY_90};
    font-size: 0.9em;
    font-style: italic;
`

type TableProps = {
    hasHeader: boolean
    caption?: string
    headers: string[]
    body: string[][]
}

const Table = ({ hasHeader, caption, headers, body }: TableProps) => {
    return (
        <TableContainer>
            <TableHtml>
                {hasHeader ? (
                    <TableHeader>
                        <tr>
                            {headers.map(columnName => {
                                return <td>{columnName}</td>
                            })}
                        </tr>
                    </TableHeader>
                ) : (
                    ''
                )}
                <tbody>
                    {body.map(row => {
                        return (
                            <tr>
                                {row.map(fieldValue => {
                                    return (
                                        <td>
                                            <Copy markdown={fieldValue} />
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </TableHtml>
            {caption ? <StyledParagraph>{caption}</StyledParagraph> : ''}
        </TableContainer>
    )
}
export default Table
