import React from 'react'
import GroupTeaser, { ActivityGroup } from '../components/GroupTeaser'
import TeaserGrid from '../components/TeaserGrid'

const GroupTeaserCollection = ({
    groups,
    currentUrl,
    params,
}: {
    groups: ActivityGroup[]
    currentUrl: string
    params: string
}) => {
    return (
        <TeaserGrid>
            {Object.values(groups).map(group => (
                <li key={group.id}>
                    <GroupTeaser group={group} currentUrl={currentUrl} params={params} />
                </li>
            ))}
        </TeaserGrid>
    )
}

export default GroupTeaserCollection
