import React from 'react'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import set from 'lodash/set'
import styled from 'styled-components'
import { useModalDispatch, actions } from '../components/modal/ModalContext'
import { BREAKPOINTS } from '../components/tokens'
import EventTeaser, { Event } from '../components/EventTeaser'

const TeaserList = styled.div`
    margin: 0;
    padding: 0 16px 24px;
`

const Container = styled.div`
    padding-bottom: 64px;

    &: last-child {
        padding-bottom: 0;
    }

    @media (min-width: ${BREAKPOINTS.tablet}px) {
        padding-bottom: 40px;
    }
`

/**
 * Mark up events schema metadata
 * @see https://schema.org/SocialEvent
 * @see https://developers.google.com/search/docs/data-types/event
 */
const markUpRichAnnotations = (events: Event[]): string => {
    if (events.length === 0) {
        return ''
    }

    const richEvents = events.map(event => {
        const schema: Record<string, unknown> = {
            '@context': 'http://schema.org',
            '@type': 'SocialEvent',
            ...event,
        }

        if (get(schema, 'location.name')) {
            set(schema, ['location', '@type'], 'Place')
        }

        if (get(schema, 'location.address.streetAddress')) {
            set(schema, ['location', 'address', '@type'], 'PostalAddress')
        }

        return schema
    })

    return JSON.stringify(richEvents)
}

const EventTeaserCollection = ({
    events,
    currentUrl,
    params,
}: {
    events: Event[]
    currentUrl: string
    params: string
}) => {
    const eventsRichAnnotations = markUpRichAnnotations(events)
    const dispatch = useModalDispatch()
    const onAttend = () =>
        dispatch(
            actions.openModal({
                title: 'Attend events',
                subTitle: 'Become a member and come along.',
                trigger: 'event',
            }),
        )

    return (
        <TeaserList>
            <Helmet>
                <script type="application/ld+json" id="events-rich-annotations">
                    {eventsRichAnnotations}
                </script>
            </Helmet>
            {Object.values(events).map(event => (
                <Container key={event.id}>
                    <EventTeaser onAttend={onAttend} event={event} currentUrl={currentUrl} params={params} />
                </Container>
            ))}
        </TeaserList>
    )
}

export default EventTeaserCollection
