/* eslint-disable no-console */
import { useEffect } from 'react'

// Enable experimental requestIdleCallback and cancelIdleCallback in TypeScript
// @see https://github.com/Microsoft/TypeScript/issues/21309
type RequestIdleCallbackHandle = any
type RequestIdleCallbackOptions = {
    timeout: number
}
type RequestIdleCallbackDeadline = {
    readonly didTimeout: boolean
    timeRemaining: () => number
}

type InterNationsRegistrationAssets = {
    js: string[]
    css: string[]
}

declare global {
    interface Window {
        requestIdleCallback: (
            callback: (deadline: RequestIdleCallbackDeadline) => void,
            opts?: RequestIdleCallbackOptions,
        ) => RequestIdleCallbackHandle
        cancelIdleCallback: (handle: RequestIdleCallbackHandle) => void
    }
}

type XMLHttpCallback = (err: Error | null, xhr?: XMLHttpRequest) => void

const useRegistrationPrefetch = () => {
    useEffect(() => {
        const queue =
            typeof window.requestIdleCallback === 'function'
                ? window.requestIdleCallback
                : function noRequestIdleCallback(callback: () => void) {
                      window.setTimeout(callback, 0)
                  }

        function support(feature: string) {
            if (typeof document === 'undefined') {
                return false
            }
            const fakeLink = document.createElement('link')
            try {
                return fakeLink.relList.supports(feature)
            } catch (err) {
                return false
            }
        }

        const resolveRequest = (request: XMLHttpRequest, callback: XMLHttpCallback) => () => {
            if (request.status >= 200 && request.status <= 400) {
                callback(null, request)
            } else {
                callback(new Error(request.responseText))
            }
        }

        function requestPrefetchStrategy(url: string, callback: XMLHttpCallback) {
            const req = new XMLHttpRequest()
            req.open('GET', url)
            req.onload = resolveRequest(req, callback)
            req.send(null)
        }

        function linkPrefetchStrategy(url: string, onPreloadResponse: (err: Error | null, data: unknown) => void) {
            const link = document.createElement('link')
            link.setAttribute('rel', 'prefetch')
            link.setAttribute('href', url)

            link.onerror = () => onPreloadResponse(new Error('link creation error'), undefined)
            link.onload = function() {
                return onPreloadResponse(null, link)
            }

            const parentElement =
                document.getElementsByTagName('head')[0] || document.getElementsByName('script')[0].parentNode
            parentElement.appendChild(link)
        }

        const prefetcher = support('prefetch') ? linkPrefetchStrategy : requestPrefetchStrategy

        function fetchInterNationsRegistrationAssets(callback: (urlResources: string[]) => void) {
            const processAssets: XMLHttpCallback = (err, xhr?) => {
                if (err || !xhr) {
                    return // 404 - 500 error ignore.
                }
                try {
                    const { js, css }: InterNationsRegistrationAssets = JSON.parse(xhr.response)
                    callback(js.concat(css))
                } catch {
                    // Skip
                }
            }

            const req = new XMLHttpRequest()
            req.open('GET', '/api/seo/registration-assets')
            req.setRequestHeader('Accept', 'application/vnd.org.internations.frontend+json')
            req.onload = resolveRequest(req, processAssets)
            req.send(null)
        }

        function main() {
            fetchInterNationsRegistrationAssets(assetsList => {
                assetsList.forEach(url => {
                    prefetcher(url, err => {
                        if (err) {
                            console.warn(`Failed to prefetch: ${url}`)
                        }
                    })
                })
            })
        }

        // Run with low priority
        queue(main)
    }, [])

    return null
}

export default useRegistrationPrefetch
