import React from 'react'
import styled from 'styled-components'
import { BREAKPOINTS, PADDING_STANDARD, COLOR_BLUE } from './tokens'
import Button from './Button'
import UserImage from './UserImage'
import Flag from './Flag'
import Icon from './Icon'

type Country = {
    iocCode: string
    name: string
}

export type User = {
    id: string
    gender: 'm' | 'f'
    imagePath: string
    localcommunityId: string
    localcommunityName: string
    countryOfOriginCode: Country
    countryOfResidencyCode: Country
}

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (min-width: ${BREAKPOINTS.tablet}px) {
        align-items: normal;
    }
`
const UserContainer = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: ${BREAKPOINTS.tablet}px) {
        flex-direction: row;
    }
`
const UserImageContainer = styled.div`
    margin-right: 8px;
    display: flex;
    flex-direction: column;
    @media (min-width: ${BREAKPOINTS.tablet}px) {
        margin-right: 16px;
    }
`
const MediaObject = styled.div`
    display: flex;
    align-items: center;
    @media (min-width: ${BREAKPOINTS.tablet}px) {
        justify-content: space-between;
    }
`
const FlagContainer = styled.div`
    display: flex;
    margin-top: ${PADDING_STANDARD / 4}px;
`
const TextContainer = styled.div`
    font-size: 11px;
    margin-top: ${PADDING_STANDARD / 2}px;
    @media (min-width: ${BREAKPOINTS.tablet}px) {
        margin: 0 ${PADDING_STANDARD * 1.5}px;
        margin-top: 10px;
        font-size: 13px;
    }
`
const MaskedUserNameContainer = styled.div`
    display: flex;
    align-items: center;
`
const MaskedUserName = styled.strong`
    margin-left: ${PADDING_STANDARD / 4}px;
    white-space: nowrap;
`
const ButtonText = styled.span`
    display: none;
    @media (min-width: ${BREAKPOINTS.tablet}px) {
        display: inline-block;
    }
`

const ButtonLink = styled.button`
    background: none;
    border: none;
    padding: 0;
    font-size: 14px;
    color: ${COLOR_BLUE};

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`

const Divider = styled.div`
    margin: 2px;
`

type Props = {
    user: Pick<User, 'gender' | 'imagePath' | 'localcommunityName' | 'countryOfOriginCode' | 'countryOfResidencyCode'>
    onButtonClick: () => void
    onMaskedUserClick: () => void
}

const UserTeaser = ({
    user: { gender, imagePath, localcommunityName, countryOfOriginCode, countryOfResidencyCode },
    onButtonClick,
    onMaskedUserClick,
}: Props) => (
    <Container>
        <UserContainer className="t-user-preview">
            <MediaObject>
                <UserImageContainer onClick={onMaskedUserClick}>
                    <UserImage
                        className="t-user-preview-image"
                        gender={gender}
                        size="large"
                        image={
                            imagePath
                                ? {
                                      width: 72,
                                      height: 72,
                                      src: imagePath.replace('{format}', '80_80-2x'),
                                      srcSet: '',
                                  }
                                : null
                        }
                    />
                </UserImageContainer>
                <div>
                    <MaskedUserNameContainer onClick={onMaskedUserClick}>
                        <Icon icon="lock" />
                        <MaskedUserName>Community Member</MaskedUserName>
                    </MaskedUserNameContainer>
                    <ButtonLink onClick={onMaskedUserClick}>Join Now</ButtonLink>
                    <FlagContainer>
                        <Flag iocCode={countryOfOriginCode.iocCode} size="small" />
                        <Divider />
                        <Flag iocCode={countryOfResidencyCode.iocCode} size="small" />
                    </FlagContainer>
                </div>
            </MediaObject>
            <TextContainer>
                From {countryOfOriginCode.name}, living in {localcommunityName}
            </TextContainer>
        </UserContainer>
        <Button onClick={onButtonClick}>
            <MediaObject>
                <Icon icon="connect" style={{ marginRight: 4 }} invertColor />
                <ButtonText className="t-user-preview-connect">Connect</ButtonText>
            </MediaObject>
        </Button>
    </Container>
)

export default UserTeaser
