import { useState, useEffect } from 'react'

const useAppendQueryParameters = () => {
    const [currentUrl, setCurrentUrl] = useState('https://www.internations.org')
    const [params, setParams] = useState('')

    useEffect(() => {
        setCurrentUrl(window.location.href)
        setParams(window.location.search)
    }, [])

    return { currentUrl, params }
}

export default useAppendQueryParameters
