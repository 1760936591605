import { useState, useEffect } from 'react'
import { BREAKPOINTS } from '../components/tokens'

const useWindowSize = () => {
    const [width, setWidth] = useState(0)

    /* eslint-disable no-undef */
    useEffect(() => {
        setWidth(window.innerWidth)
    }, [])

    useEffect(() => {
        window.addEventListener('resize', () => {
            setWidth(window.innerWidth)
        })
        return () =>
            window.removeEventListener('resize', () => {
                setWidth(window.innerWidth)
            })
    }, [])
    /* eslint-enable no-undef */

    const isMobile = width < BREAKPOINTS.tablet
    const isBelowDesktop = width < BREAKPOINTS.desktop
    const isAboveTablet = width > BREAKPOINTS.tablet

    return { isMobile, isBelowDesktop, isAboveTablet }
}

export default useWindowSize
