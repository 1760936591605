import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Gender } from '../types/wordpress'
import { UserImageSize } from '../components/UserImage'

const SIZES = {
    small: '32px',
    normal: '56px',
    large: '72px',
}

const Placeholder = ({ size = 'normal', gender }: { size: UserImageSize; gender: Gender }) => {
    const userImage = useStaticQuery(graphql`
        fragment userPlaceholderSmall on File {
            childImageSharp {
                fixed(height: 32, width: 32) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        fragment userPlaceholderNormal on File {
            childImageSharp {
                fixed(height: 56, width: 56) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        fragment userPlaceholderLarge on File {
            childImageSharp {
                fixed(height: 72, width: 72) {
                    ...GatsbyImageSharpFixed
                }
            }
        }
        query {
            small_m: file(relativePath: { eq: "users/placeholder-m.png" }) {
                ...userPlaceholderSmall
            }
            small_f: file(relativePath: { eq: "users/placeholder-f.png" }) {
                ...userPlaceholderSmall
            }
            normal_m: file(relativePath: { eq: "users/placeholder-m.png" }) {
                ...userPlaceholderNormal
            }
            normal_f: file(relativePath: { eq: "users/placeholder-f.png" }) {
                ...userPlaceholderNormal
            }
            large_m: file(relativePath: { eq: "users/placeholder-m.png" }) {
                ...userPlaceholderLarge
            }
            large_f: file(relativePath: { eq: "users/placeholder-f.png" }) {
                ...userPlaceholderLarge
            }
        }
    `)

    return (
        <Img
            style={{ minWidth: SIZES[size], borderRadius: '50%' }}
            fixed={userImage[`${size}_${gender}`].childImageSharp.fixed}
        />
    )
}

export default Placeholder
