import React from 'react'
import styled from 'styled-components'
import { COLOR_LIGHT_BLUE } from './tokens'
import appendQueryParameters from '../utils/getQueryParams'

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: -4px;
    box-sizing: border-box;
`
const Item = styled.div`
    display: flex;
    width: 50%;
    padding: 4px;
    text-align: center;
`
const Link = styled.a`
    display: flex;
    height: auto;
    width: 100%;
    padding: 8px;
    background: ${COLOR_LIGHT_BLUE};
    text-align: center;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
`

export type LinkBoxLinks = {
    url: string
    title: string
    target?: string
    testClass?: string
}

type LinkBoxProps = {
    links: LinkBoxLinks[]
    currentUrl: string
    params: string
}

const LinkBoxContainer = ({ links, currentUrl, params }: LinkBoxProps) => (
    <Container>
        {links.map(link => (
            <Item key={link.title}>
                <Link className={link.testClass} href={appendQueryParameters(link.url, currentUrl, params)}>
                    {link.title}
                </Link>
            </Item>
        ))}
    </Container>
)

export default LinkBoxContainer
