import React from 'react'
import styled from 'styled-components'

const VideoContainer = styled.div`
    position: relative;

    &:before {
        display: block;
        padding-top: 56.25%;
        content: '';
        width: 100%;
    }
`

const VideoContent = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`

const VideoIFrame = ({ url }: { url: string }) => (
    <iframe title={url} src={url} width="100%" height="100%" frameBorder="0" allowFullScreen />
)

type VideoProps = {
    videoUrl: string
}

const Video = ({ videoUrl }: VideoProps) => {
    return (
        <VideoContainer>
            <VideoContent>
                <VideoIFrame url={videoUrl} />
            </VideoContent>
        </VideoContainer>
    )
}
export default Video
