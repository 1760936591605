import { useEffect } from 'react'

type Targeting = { ingodest?: string; ingocat?: string }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type WithGT = { googletag: any }
const isWithGT = (o: object): o is WithGT => 'googletag' in o

const useGoogleTargeting = (targeting: Targeting) => {
    useEffect(() => {
        if (!isWithGT(window)) {
            return
        }
        const gt = window.googletag
        gt.cmd.push(() => {
            Object.entries(targeting).forEach(([key, value]) => {
                if (value != null) {
                    gt.pubads().setTargeting(key, value)
                }
            })
        })
    }, [targeting])

    return null
}

export default useGoogleTargeting
