import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { ImageIconName } from './Icon'
import MetaInformation from './MetaInformation'
import Button, { MobileButton } from './Button'
import { EventTeaserTitle } from './typography'
import { BREAKPOINTS, PADDING_STANDARD } from './tokens'
import appendQueryParameters from '../utils/getQueryParams'
import useWindowSize from '../hooks/useWindowSize'
import Collapsible from './Collapsible'
import ContentMasking, { replacePlaceholder } from './ContentMasking'

type TeaserProps = {
    title: string
    url: string
    image: string
    actionText?: string
    metaInfo?: { icon: ImageIconName; text: React.ReactNode }[]
    onAction?: () => void
    className?: string
    currentUrl: string
    params: string
    description?: string
}

const Box = styled.div`
    display: flex;

    justify-content: flex-start;
    align-items: stretch;

    flex-direction: row;
    flex-wrap: nowrap;
    align-content: stretch;
`

const FlexGrow = styled.div`
    flex-grow: 1;
`

const TextWrapper = styled.div`
    font-size: 16px;
    margin-top: ${PADDING_STANDARD / 2}px;
    @media (min-width: ${BREAKPOINTS.tablet}px) {
        margin-top: 10px;
    }
`

const Text = styled.div`
    display: flex;
`

const Truncate = styled.div`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
`

const FixedImageSize = styled.div`
    flex-basis: '353px';
`

const SingleTeaser = ({
    className,
    title,
    url,
    metaInfo,
    actionText,
    onAction,
    image,
    currentUrl,
    params,
    description,
}: TeaserProps) => {
    const { isMobile } = useWindowSize()

    const metaInfoMarkup =
        metaInfo !== undefined ? (
            <div>
                {metaInfo.map(({ icon, text }, index) => {
                    const item = (
                        <MetaInformation key={icon} icon={icon}>
                            {text}
                        </MetaInformation>
                    )

                    return index ? (
                        <div style={{ marginTop: isMobile ? '4px' : '8px' }} key={icon}>
                            {item}
                        </div>
                    ) : (
                        item
                    )
                })}
            </div>
        ) : null

    const eventImage = (
        <Img
            className={className}
            style={{ marginRight: '16px', borderRadius: '12px' }}
            fixed={{ width: 96, height: 96, src: image?.replace('{format}', '80_80-2x'), srcSet: '' }}
        />
    )

    const clickableEventTitle = (
        <a href={appendQueryParameters(url, currentUrl, params)} title={title}>
            <EventTeaserTitle>{title}</EventTeaserTitle>
        </a>
    )

    if (isMobile) {
        return (
            <>
                <Box>
                    <FixedImageSize>{eventImage}</FixedImageSize>

                    <Text>
                        <FlexGrow>
                            <Truncate>{clickableEventTitle}</Truncate>
                            {metaInfoMarkup}
                        </FlexGrow>
                    </Text>
                </Box>

                <TextWrapper>
                    {description ? (
                        <Collapsible
                            shortContent={replacePlaceholder(description)}
                            fullContent={<ContentMasking content={description} />}
                            visibleTextHeight={200}
                        />
                    ) : null}
                </TextWrapper>

                <MobileButton className={`${className}-btn`} onClick={onAction}>
                    {actionText}
                </MobileButton>
            </>
        )
    }

    return (
        <Box>
            <FixedImageSize>{eventImage}</FixedImageSize>

            <FlexGrow>
                <Text>
                    <FlexGrow>
                        {clickableEventTitle}
                        {metaInfoMarkup}
                    </FlexGrow>

                    <Button className={`${className}-btn`} onClick={onAction}>
                        {actionText}
                    </Button>
                </Text>

                <TextWrapper>
                    {description ? (
                        <Collapsible
                            shortContent={replacePlaceholder(description)}
                            fullContent={<ContentMasking content={description} />}
                            visibleTextHeight={300}
                        />
                    ) : null}
                </TextWrapper>
            </FlexGrow>
        </Box>
    )
}

export default SingleTeaser
