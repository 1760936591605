import React from 'react'
import Helmet from 'react-helmet'
import { CorePage as CorePageType, MetaElement } from '../types/wordpress'

const getCanonicalUrl = (metaElements: MetaElement[]): string => {
    const ogURLProperty = metaElements.find(element => element.property === 'og:url')

    if (!ogURLProperty) {
        return ''
    }

    return ogURLProperty.content.replace('http://', 'https://')
}

const PageHeader = ({ meta: { title, metaElements } }: Pick<CorePageType, 'meta'>) => {
    const children: JSX.Element[] = []
    const canonicalUrl = getCanonicalUrl(metaElements)

    if (canonicalUrl) {
        children.push(<link key="canonical" rel="canonical" href={canonicalUrl} />)
    }

    return (
        <Helmet
            title={title}
            meta={metaElements}
            htmlAttributes={{
                lang: 'en',
            }}
        >
            {children}
        </Helmet>
    )
}

export default PageHeader
