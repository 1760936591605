import React from 'react'
import styled from 'styled-components'
import Flag from './Flag'
import { SubHeading } from './typography'
import { PADDING_STANDARD } from './tokens'
import appendQueryParameters from '../utils/getQueryParams'

type Nationality = {
    title: string
    url: string
    iocCode: string
}

const HalfSpacing = styled.div`
    margin-top: 8px;
`
const NationalityListContainer = styled.div`
    padding: 24px ${PADDING_STANDARD}px 0px ${PADDING_STANDARD}px;
`

const NationalityList = ({
    nationalities,
    currentUrl,
    params,
}: {
    nationalities: Nationality[]
    currentUrl: string
    params: string
}) => {
    return (
        <>
            <NationalityListContainer className="t-nationality-list">
                {nationalities.map(nationality => (
                    <React.Fragment key={nationality.title}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Flag iocCode={nationality.iocCode} size="normal" />
                            <a
                                className={`t-nationality-list-entry-${nationality.iocCode}`}
                                href={appendQueryParameters(nationality.url, currentUrl, params)}
                                style={{ paddingLeft: `${PADDING_STANDARD}px` }}
                            >
                                <SubHeading noMargin>{nationality.title}</SubHeading>
                            </a>
                        </div>
                        <HalfSpacing />
                    </React.Fragment>
                ))}
            </NationalityListContainer>
        </>
    )
}

export default NationalityList
