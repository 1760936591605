import React from 'react'
import styled from 'styled-components'
import { Bling as GPT } from 'react-gpt'
import WhiteBox from './WhiteBox'
import { Heading, SidebarHeading } from './typography'
import useWindowSize from '../hooks/useWindowSize'

const AD_UNIT_COUNT = process.env.NODE_ENV === 'production' ? 6 : 0
const GOOGLE_DFP_NETWORK_CODE = process.env.GOOGLE_DFP_NETWORK_CODE

const AdsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
`

const AdUnit = ({ adUnitPath }: { adUnitPath: string }) => (
    <GPT
        adUnitPath={adUnitPath}
        slotSize={[120, 60]}
        collapseEmptyDiv
        forceSafeFrame
        safeFrameConfig={{
            allowOverlayExpansion: false,
            allowPushExpansion: false,
            sandbox: true,
        }}
    />
)

const GlobalPartners = () => {
    const { isBelowDesktop } = useWindowSize()
    const adUnits = []

    for (let adUnit = 0; adUnit < AD_UNIT_COUNT; adUnit += 1) {
        const adUnitPath = `/${GOOGLE_DFP_NETWORK_CODE}/Seo-Page-Global-Partner-${
            isBelowDesktop ? 'Mobile' : 'Desktop'
        }-${adUnit}`
        adUnits.push(<AdUnit key={adUnit} adUnitPath={adUnitPath} />)
    }

    if (isBelowDesktop) {
        return (
            <WhiteBox>
                <Heading>Our Global Partners</Heading>
                <AdsContainer>{adUnits}</AdsContainer>
            </WhiteBox>
        )
    }

    return (
        <>
            <SidebarHeading>Our Global Partners</SidebarHeading>
            <WhiteBox>
                <AdsContainer>{adUnits}</AdsContainer>
            </WhiteBox>
        </>
    )
}

export default GlobalPartners
