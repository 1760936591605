/* eslint react/no-danger: 0 */
import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { get } from 'lodash'
import { CallToAction as CallToActionType } from '../types/wordpress'
import {
    COLOR_WHITE,
    COLOR_HOVER_BLUE,
    COLOR_BLUE,
    COLOR_HOVER_LIGHT_BLUE,
    PADDING_STANDARD,
    DEFAULT_BORDER_RADIUS,
    BREAKPOINTS,
    BOX_SHADOW_Z3,
} from './tokens'
import Button from './Button'
import Copy from './Copy'
import { Heading } from './typography'
import appendQueryParameters from '../utils/getQueryParams'

const Container = styled.a`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(to bottom, ${COLOR_HOVER_BLUE}, #2ba0e3);
    padding: 24px ${PADDING_STANDARD}px;
    border-radius: ${DEFAULT_BORDER_RADIUS}px;

    p {
        margin: 0;
    }

    ul {
        text-align: left;
    }

    @media (min-width: ${BREAKPOINTS.tablet}px) {
        flex-direction: row;
    }

    &:hover {
        text-decoration: none;
        cursor: pointer;
    }
`

const Content = styled.div`
    flex-grow: 1;
    text-align: center;
    margin: ${PADDING_STANDARD * 2}px 0;
    color: ${COLOR_WHITE};

    @media (min-width: ${BREAKPOINTS.tablet}px) {
        text-align: left;
        margin: 0 ${PADDING_STANDARD * 2}px;
    }
`

const StyledButton = styled(Button)`
    line-height: 40px;
    background: ${COLOR_WHITE};
    color: ${COLOR_BLUE};

    &:hover {
        text-decoration: none;
        background-color: ${COLOR_HOVER_LIGHT_BLUE};
        box-shadow: ${BOX_SHADOW_Z3};
        cursor: pointer;
    }
`

const DefaultPic = () => {
    const data = useStaticQuery(graphql`
        query {
            default: file(relativePath: { eq: "pictogram/registration.png" }) {
                childImageSharp {
                    fixed(height: 112, width: 112) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `)

    return <Img style={{ flexShrink: 0 }} fixed={data.default.childImageSharp.fixed} />
}

const CallToAction = ({ headline, copy, actionLabel, actionUrl, pictogram, currentUrl, params }: CallToActionType) => {
    const src = get(pictogram, 'localFile.publicURL')

    const fixedObject: FixedObject = {
        src,
        width: 112,
        height: 112,
        srcSet: '',
    }

    return (
        <Container title="InterNations | Registration" href={appendQueryParameters(actionUrl, currentUrl, params)}>
            {src ? <Img fixed={fixedObject} style={{ flexShrink: 0 }} /> : <DefaultPic />}

            <Content>
                {headline ? <Heading white>{headline}</Heading> : null}
                {copy ? <Copy markdown={copy} /> : null}
            </Content>
            <StyledButton>{actionLabel}</StyledButton>
        </Container>
    )
}

CallToAction.defaultProps = {
    headline: null,
    copy: null,
    pictogram: null,
    actionLabel: 'Click here',
    actionUrl: 'https://internations.org',
}

export default CallToAction
