import React, { useState } from 'react'
import styled from 'styled-components'
import { COLOR_BLUE, BREAKPOINTS } from './tokens'
import Icon from './Icon'

type CollapsibleProps = {
    fullContent: JSX.Element | string
    shortContent: JSX.Element | string
    visibleTextHeight?: number
    isMasked?: boolean
}

const Tranparent = styled.div`
    -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
`

const Container = styled.div`
    margin-bottom: 20px;
`

const ButtonLink = styled.button`
    display: flex;

    background: none;
    border: none;
    padding: 0;
    font-size: 14px;
    color: ${COLOR_BLUE};

    font-weight: normal;
    height: 20px;
    letter-spacing: 0.48px;
    line-height: 20px;
    margin-top: 10px;

    &:hover {
        cursor: pointer;
    }

    @media (min-width: ${BREAKPOINTS.tablet}px) {
        margin: 10px auto 0;
    }
`

const Collapsible = ({ fullContent, shortContent, visibleTextHeight, isMasked = true }: CollapsibleProps) => {
    const [showMore, setShowMore] = useState(false)

    let short

    if (isMasked) {
        short = <Tranparent>{String(shortContent).substring(0, visibleTextHeight)}</Tranparent>
    } else {
        short = <Tranparent>{shortContent}</Tranparent>
    }

    return (
        <Container>
            <div>{showMore ? fullContent : short}</div>

            <ButtonLink onClick={() => setShowMore(!showMore)}>
                <Icon icon={showMore ? 'showLess' : 'showMore'} />
                {showMore ? 'Read less' : 'Read more'}
            </ButtonLink>
        </Container>
    )
}

export default Collapsible
