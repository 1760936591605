import { useEffect } from 'react'

const useCookiebot = () => {
    useEffect(() => {
        const cookiebotScript = document.createElement('script')
        cookiebotScript.setAttribute('src', 'https://consent.cookiebot.com/uc.js')
        cookiebotScript.setAttribute('type', 'text/javascript')
        cookiebotScript.setAttribute('id', 'Cookiebot')
        cookiebotScript.setAttribute('data-culture', 'EN')
        cookiebotScript.setAttribute('data-framework', 'TCFv2.2')
        cookiebotScript.setAttribute('data-cbid', '027a2704-49bf-4fec-b7c7-65773549910a')

        document.head.appendChild(cookiebotScript)
    }, [])

    return null
}

export default useCookiebot
