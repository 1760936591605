import React from 'react'
import Teaser from './Teaser'

export type ActivityGroup = {
    id: string
    title: string
    url: string
    moodImage: string
    localcommunityId: number
}

type GroupTeaserProps = {
    group: ActivityGroup
    currentUrl: string
    params: string
}

const GroupTeaser = ({ group, currentUrl, params }: GroupTeaserProps) => {
    const { title, url, moodImage } = group
    return <Teaser title={title} url={url} image={moodImage} currentUrl={currentUrl} params={params} />
}

export default GroupTeaser
