import React from 'react'
import Image from 'gatsby-image'
import styled from 'styled-components'
import Video from '../components/Video'
import Table from '../components/Table'
import Quote from '../components/Quote'
import Stats from '../components/Stats'
import { ContentItem as WpContentItem } from '../types/wordpress'

import Copy from '../components/Copy'
import { Heading } from '../components/typography'
import QAs from './QAs'

function assertNever<T>(value: never): T {
    throw new Error(`The impossible happened: \n${JSON.stringify(value, null, 4)}`)
}

const ImagePadding = styled.div`
    padding: 8px 0;
`

const ContentItem = (item: WpContentItem, key: number) => {
    if (item.acf_fc_layout === 'headline') {
        return (
            <Heading
                id={item.headline
                    .replace(/[&\/\#, +()$~%.'":@^*?<>{}]/g, ' ')
                    .replace(/\s+/g, '-')
                    .toLowerCase()}
                key={`heading-${key}`}
            >
                {item.headline}
            </Heading>
        )
    }

    if (item.acf_fc_layout === 'copy') {
        return <Copy key={`copy-${key}`} markdown={item.copy} />
    }

    if (item.acf_fc_layout === 'qas') {
        return <QAs key={`qas-${key}`} qas={item.qas} />
    }

    if (item.acf_fc_layout === 'image') {
        return (
            <ImagePadding>
                <Image key={`image-${key}`} fluid={item.image.localFile.childImageSharp.fluid} />
            </ImagePadding>
        )
    }

    if (item.acf_fc_layout === 'video') {
        return <Video key={`video-${key}`} videoUrl={item.video} />
    }

    if (item.acf_fc_layout === 'table') {
        return (
            <Table
                key={`table-${key}`}
                hasHeader={item.table.use_header}
                caption={item.table.caption}
                headers={item.table.header}
                body={item.table.body}
            />
        )
    }

    if (item.acf_fc_layout === 'quote') {
        return (
            <Quote
                key={`copy-${key}`}
                name={item.quote.name}
                quote={item.quote.content_quote}
                picture={item.quote.picture}
            />
        )
    }

    if (item.acf_fc_layout === 'stats') {
        return <Stats key={`stats-${key}`} stats={item.stats} />
    }

    return assertNever(item)
}

export default ContentItem
