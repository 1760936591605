import React from 'react'
import styled from 'styled-components'
import Img, { FixedObject } from 'gatsby-image'
import { Gender } from '../types/wordpress'
import Placeholder from './Placeholder'
import Icon from './Icon'
import { COLOR_BLUE } from './tokens'

export type UserImageSize = 'small' | 'normal' | 'large'

export const SIZES = {
    small: '32px',
    normal: '56px',
    large: '72px',
}

const DefaultImage = ({ gender, size }: { gender: Gender; size: UserImageSize }) => (
    <Placeholder gender={gender} size={size} />
)

/* @FIXME: INOTF-1930 reenable accessibility checks */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
const UserImage = ({
    gender,
    image,
    size = 'normal',
    onClick,
    className,
}: {
    gender: Gender
    image: FixedObject | null
    size?: UserImageSize
    onClick?: () => void
    className?: string
}) => (
    <div onClick={onClick}>
        {image ? (
            <Img
                className={className}
                style={{ minWidth: size ? SIZES[size] : null, borderRadius: '50%' }}
                fixed={image}
            />
        ) : (
            <DefaultImage gender={gender} size={size} />
        )}
    </div>
)
/* eslint-enable jsx-a11y/no-static-element-interactions */
/* eslint-enable jsx-a11y/click-events-have-key-events */

export default UserImage

const MoreContainer = styled.div<{ size?: UserImageSize }>`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${COLOR_BLUE};
    border-radius: 50%;
    width: ${props => (props.size ? SIZES[props.size] : SIZES.normal)};
    height: ${props => (props.size ? SIZES[props.size] : SIZES.normal)};
`

export const MemberMore = ({ size }: { size?: UserImageSize }) => (
    <MoreContainer size={size}>
        <Icon icon="chevronRight" invertColor />
    </MoreContainer>
)
