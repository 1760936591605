import React from 'react'

type FlagSize = 'small' | 'normal'

const Flag = ({ iocCode, size }: { iocCode: string; size: FlagSize }) => {
    const sizepx = size === 'normal' ? '32' : '16'
    const classString = `flag-${sizepx} flag-${iocCode}`

    return <i className={classString} />
}

export default Flag
