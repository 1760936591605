import React from 'react'
import parseISO from 'date-fns/parseISO'

type Props = { date: string; hideYear?: boolean }

/**
 * Format Date
 * Pass 'undefined' for locales. Omitting the param will result in a wrong locale chosen.
 * Passing in 'default' will not work in IE/Edge 17
 *
 * @example
 * formatDate(new Date())
 * // => '9 Oct 2019, 10:00'
 */
const formatDate = new Intl.DateTimeFormat(undefined, {
    hour: 'numeric',
    minute: 'numeric',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
}).format

// TODO INOTF-1926 To be removed once the API is updated
const formatDateHideYear = new Intl.DateTimeFormat(undefined, {
    hour: 'numeric',
    minute: 'numeric',
    month: 'short',
    day: 'numeric',
}).format

/**
 * Formated date string component
 */
const MetaDate = ({ date, hideYear }: Props) => {
    const parsedDate = parseISO(date)
    const formattedDate = hideYear ? formatDateHideYear(parsedDate) : formatDate(parsedDate)

    return <>{formattedDate}</>
}

export default MetaDate
