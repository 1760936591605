import React from 'react'
import Flag from './Flag'
import { LanguageLink } from '../types/wordpress'
import appendQueryParameters from '../utils/getQueryParams'

export const langLinks = {
    headline: 'Other Languages',
    links: [
        {
            title: 'Português',
            url: '/honduras-expats/brazilians/pt',
            languageFlag: 'por',
        },
        {
            title: 'Italiano',
            url: '/honduras-expats/brazilians/pt',
            languageFlag: 'ita',
        },
        {
            title: 'German',
            url: '/honduras-expats/brazilians/pt',
            languageFlag: 'ger',
        },
        {
            title: 'Espanol',
            url: '/honduras-expats/brazilians/pt',
            languageFlag: 'esp',
        },
        {
            title: 'Francais',
            url: '/honduras-expats/brazilians/pt',
            languageFlag: 'fra',
        },
    ].map(({ title, url, languageFlag }) => ({
        link: { title, url },
        languageFlag: { countryCodeIoc: languageFlag, countryCodeIso: 'fk', countrySlug: 'fakistan' },
    })),
}

/**
 * Delete me plz
 * @see https://issues.internations.org/browse/INOTF-1997
 */
const fixNationalityUrl = (url: string): string => {
    if (url.includes('/danes')) {
        return url.replace('danes', 'danish')
    }
    if (url.includes('/finns')) {
        return url.replace('finns', 'finnish')
    }
    if (url.includes('/poles')) {
        return url.replace('poles', 'polish')
    }
    if (url.includes('/spaniards')) {
        return url.replace('spaniards', 'spanish')
    }
    if (url.includes('/swedes')) {
        return url.replace('swedes', 'swedish')
    }
    if (url.includes('/turks')) {
        return url.replace('turks', 'turkish')
    }
    if (url.includes('/brits')) {
        return url.replace('brits', 'british')
    }
    return url
}

const LanguageLinks = ({
    links,
    currentUrl,
    params,
}: {
    links: LanguageLink[]
    currentUrl: string
    params: string
}) => (
    <>
        {links.map(({ link: { title, url }, languageFlag: { countryCodeIoc } }) => (
            <a
                key={title}
                title={title}
                href={appendQueryParameters(fixNationalityUrl(url), currentUrl, params)}
                style={{ marginRight: '8px' }}
            >
                <Flag iocCode={countryCodeIoc} size="normal" />
            </a>
        ))}
    </>
)

export default LanguageLinks
