import React from 'react'
import styled from 'styled-components'
import { COLOR_BLUE } from './tokens'
import { useModalDispatch, actions } from '../components/modal/ModalContext'
import Icon from './Icon'

const TWIG_TAG_REGEX = /(\{%.+?%\})(.*?)(\{%.+?%\})/gm
const PROTECTED_CONTENT_PLACEHOLDER = '%PROTECTEDCONTENT%'
const PROTECTED_CONTENT = ' (...) Protected content (...) '

type ContentMaskingProps = {
    content: string
}

export const replacePlaceholder = (value: string): string =>
    value.replace(/(\r\n|\n|\r)/g, ' ').replace(TWIG_TAG_REGEX, PROTECTED_CONTENT)

/* should be refactored as it is over the place */
const ButtonLink = styled.button`
    background: none;
    border: none;
    padding: 0;
    font-size: 16px;
    color: ${COLOR_BLUE};

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`

const ContentMasking = ({ content }: ContentMaskingProps) => {
    const dispatch = useModalDispatch()
    if (!content) {
        return null
    }

    const onAction = () =>
        dispatch(
            actions.openModal({
                title: 'Attend events',
                subTitle: 'Become a member and come along.',
                trigger: 'protected_content',
            }),
        )

    const result = content
        .replace(/(\r\n|\n|\r)/g, ' ')
        .replace(TWIG_TAG_REGEX, PROTECTED_CONTENT_PLACEHOLDER)
        .split(/\s/)

    return (
        <>
            {result.map(value => {
                if (value.trim() === PROTECTED_CONTENT_PLACEHOLDER) {
                    return (
                        <>
                            (...)
                            <ButtonLink className="protected-content-btn" onClick={onAction}>
                                <Icon icon="lock" />
                                Protected content
                            </ButtonLink>{' '}
                        </>
                    )
                }
                return `${value} `
            })}
        </>
    )
}

export default ContentMasking
