import { useEffect } from 'react'

const HTTP_NO_CONTENT = 204
const USER_CHECK_URL = '/api/authentication/status'

const useRedirect = () => {
    useEffect(() => {
        const xhr = new XMLHttpRequest()

        xhr.open('HEAD', USER_CHECK_URL, true)

        xhr.setRequestHeader('Accept', 'application/vnd.org.internations.frontend+json')
        xhr.setRequestHeader('Content-Type', 'application/json')

        xhr.onload = () => {
            const isAuthenticated = xhr.status === HTTP_NO_CONTENT

            if (isAuthenticated) {
                window.location.replace('/start/')
            }
        }
        xhr.send()
    }, [])
}
export default useRedirect
