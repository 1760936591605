import React from 'react'
import styled from 'styled-components'
import CTA from '../CTA'
import { BREAKPOINTS } from '../tokens'
import { CallToAction } from '../../types/wordpress'
import useAppendQueryParameters from '../../hooks/useAppendedQueryParam'

const Container = styled.div`
    padding: 0 8px;
    margin: 16px 0;

    @media (min-width: ${BREAKPOINTS.staticHeaderTablet}px) {
        margin-top: 16px;
    }
`

export const StaticBanner = () => {
    return (
        <Container>
            <CTA
                copy="Just click the button"
                headline="Enjoy events & group activities with other expats like you and make the most of your time abroad."
                actionUrl="https://www.internations.org/registration"
                actionLabel="Join Now"
                currentUrl="https://www.internations.org/registration"
                params="trigger=UB_seotest_fbtemplate_CTA_top"
            />
        </Container>
    )
}

export const DynamicBanner = ({ callToAction }: { callToAction: CallToAction }) => {
    const { currentUrl } = useAppendQueryParameters()
    return (
        <Container>
            <CTA
                copy={callToAction.copy}
                headline={callToAction.headline}
                actionUrl={callToAction.actionUrl}
                actionLabel={callToAction.actionLabel}
                pictogram={callToAction.pictogram}
                currentUrl={currentUrl}
                params={callToAction.params}
            />
        </Container>
    )
}
