import React from 'react'
import styled from 'styled-components'
import ListOfItems from '../components/ListOfItems'
import { actions, useModalDispatch } from '../components/modal/ModalContext'
import UserTeaser, { User as UserType } from '../components/UserTeaser'

type Props = {
    users: UserType[]
}

const SpaceAround = styled.div`
    margin: 24px 16px;
`

const UserTeasers = ({ users }: Props) => {
    const dispatch = useModalDispatch()
    const onButtonClick = () =>
        dispatch(
            actions.openModal({
                title: 'Network & connect',
                subTitle: 'Become a member to browse and search profiles.',
                trigger: 'user',
            }),
        )

    const onMaskedUserClick = () =>
        dispatch(
            actions.openModal({
                title: 'Network & connect',
                subTitle: 'Become a member to browse and search profiles.',
                trigger: 'masked_link',
            }),
        )

    return (
        <ListOfItems>
            {users.map(({ id, gender, imagePath, localcommunityName, countryOfOriginCode, countryOfResidencyCode }) => (
                <SpaceAround key={id}>
                    <UserTeaser
                        user={{
                            gender,
                            imagePath,
                            localcommunityName,
                            countryOfOriginCode,
                            countryOfResidencyCode,
                        }}
                        onButtonClick={onButtonClick}
                        onMaskedUserClick={onMaskedUserClick}
                    />
                </SpaceAround>
            ))}
        </ListOfItems>
    )
}

export default UserTeasers
