/* eslint-disable react/no-danger */
import React from 'react'
import { CorePage as WpCorePage } from '../types/wordpress'

type Props = {
    page: WpCorePage
}

/**
 *
 * @example
 * /munich-expats              => ['seo/localcommunity/munich', 'seo/localcommunity/:name']
 * /munich-expats/russians     => ['seo/localcommunity/munich/nationality/russians', 'seo/localcommunity/:name/nationality/:countryCodeIoc']
 * /munich-expats/russians/ru  => ['seo/localcommunity/munich/nationality/russians/language/ru', 'seo/localcommunity/:name/nationality/:countryCodeIoc/language/:langCode']
 * /munich-expats/ru           => ['seo/localcommunity/munich/language/ru', 'seo/localcommunity/:name/language/:langCode']
 * /germany-expats/ru          => ['seo/country/germany/language/ru', 'seo/country/:name/language/:langCode']
 * /germany-expats/russians/ru => ['seo/country/germany/nationality/russians/language/ru', 'seo/country/:name/nationality/:countryCodeIoc/language/:langCode']
 * /germany-expats/russians    => ['seo/country/germany/nationality/russians', 'seo/country/:name/nationality/:countryCodeIoc']
 * /germany-expats             => ['seo/country/germany', 'seo/country/:name']
 * /newport-expats             => ['seo/upcomingCommunity/newport', 'seo/upcomingCommunity/:name']
 */
const getCustomPageName = (page: WpCorePage): [string, string] => {
    let name = ''
    if (page.geography.type === 'country') {
        name = page.geography.country.countrySlug
    } else if (page.geography.type === 'upcomingCommunity') {
        name = page.geography.upcomingCommunity.citySlug
    } else if (page.geography.type === 'localcommunity') {
        name = page.geography.localCommunity.citySlug
    } else if (page.geography.type === 'worldwide') {
        name = 'worldwide'
    }

    let pageName = `seo/${page.geography.type}/${name}`
    let pagePattern = `seo/${page.geography.type}/:name`

    if (page.targetAudience.nationality) {
        pageName += `/nationality/${page.targetAudience.nationality.countryCodeIoc}`
        pagePattern += '/nationality/:countryCodeIoc'
    }
    if (page.targetAudience.languageCode !== 'en') {
        pageName += `/language/${page.targetAudience.languageCode}`
        pagePattern += '/language/:langCode'
    }

    return [pageName, pagePattern]
}

const NewRelicCustoms = ({ page }: Props) => {
    const [pageName, pagePattern] = getCustomPageName(page)

    return (
        <script
            dangerouslySetInnerHTML={{
                __html: `
!(function (newrelic) {
  if (newrelic == null) {
    return;
  }
  newrelic.setPageViewName("${pageName}")
  newrelic.setCurrentRouteName("${pagePattern}")

})(newrelic);`,
            }}
        />
    )
}

export default NewRelicCustoms
