import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { BREAKPOINTS, COLOR_LIGHT_BLUE } from './tokens'
import useWindowSize from '../hooks/useWindowSize'
import Icon from './Icon'

const BlueBox = styled.div`
    background: ${COLOR_LIGHT_BLUE};
    border-radius: 12px;
`

const Box = styled.div`
    display: flex;

    justify-content: flex-start;
    align-items: stretch;

    flex-direction: row;
    flex-wrap: nowrap;
    align-content: stretch;

    padding: 24px;
`

const FlexGrow = styled.div`
    flex-grow: 1;
`

const TextWrapper = styled.div`
    font-family: Bryant;
    font-size: 22px;
    font-weight: normal;
    letter-spacing: -0.31px;
    line-height: 26px;
    text-align: left;
`

const StyledQuote = styled.div`
    display: flex;
    width: 24px;
    margin-right: 8px;
`

const StyledName = styled.div`
    margin-top: 16px;
    color: rgba(0, 0, 0, 0.87);
    font-family: Bryant, sans-serif;
    font-size: 22px;

    font-weight: bold;
    height: 24px;
    letter-spacing: 0.17px;
    line-height: 24px;
    text-align: left;

    @media (min-width: ${BREAKPOINTS.tablet}px) {
        margin-top: 5px;
        text-align: center;
        font-size: 18px;
    }
`

const UserContainer = styled.div`
    display: flex;
    flex-direction: column;
    @media (min-width: ${BREAKPOINTS.tablet}px) {
        flex-direction: row;
    }
`
const UserImageContainer = styled.div`
    margin-right: 8px;
    display: flex;
    flex-direction: column;
    @media (min-width: ${BREAKPOINTS.tablet}px) {
        margin-right: 16px;
    }
    height: 100%;
`
const MediaObject = styled.div`
    display: flex;
    align-items: center;
    @media (min-width: ${BREAKPOINTS.tablet}px) {
        justify-content: space-between;
    }
`

const Quote = ({ name, quote, picture }: { name: string; quote: string; picture: any }) => {
    const { isMobile } = useWindowSize()

    if (isMobile) {
        return (
            <BlueBox>
                <Box>
                    <FlexGrow>
                        <StyledQuote>
                            <Icon icon="quote" />
                        </StyledQuote>
                    </FlexGrow>
                    <FlexGrow>
                        <TextWrapper>{quote}</TextWrapper>
                        <StyledName>{name}</StyledName>
                    </FlexGrow>
                </Box>
            </BlueBox>
        )
    }

    return (
        <BlueBox>
            <Box>
                <UserContainer className="t-user-preview">
                    <MediaObject>
                        <UserImageContainer>
                            <Img
                                style={{ width: '72px', height: '72px', borderRadius: '50%' }}
                                fluid={picture.localFile.childImageSharp.fluid}
                            />
                            <StyledName>{name}</StyledName>
                        </UserImageContainer>
                    </MediaObject>
                </UserContainer>
                <StyledQuote>
                    <Icon icon="quote" />
                </StyledQuote>
                <FlexGrow>
                    <TextWrapper>{quote}</TextWrapper>
                </FlexGrow>
            </Box>
        </BlueBox>
    )
}

export default Quote
