import styled from 'styled-components'

const TeaserGrid = styled.ul`
    margin: 0;
    padding: 0 16px 24px;

    > li {
        display: inline-block;
        width: 48%;
        margin: 8px 1%;
    }

    @supports (display: grid) {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(272px, 1fr));
        gap: 16px;

        > li {
            display: block;
            width: auto;
            margin: 0;
        }
    }
`

export default TeaserGrid
